@import "variables.css";

* {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  /* This sets the rem to 10px */
  font-size: 62.5%;
}

body {
  /* font-family: "Noto Sans Display", sans-serif; */
  font-family: "Open Sans", sans-serif;
  background-color: #fff;
  overflow: hidden;
}

.container {
  width: 120rem;
}

.section-container {
  max-width: 120rem;
  padding: 20rem 14rem 6rem 14rem;
}

.secondary__section-container {
  max-width: 120rem;
  padding: 6rem 14rem 6rem 14rem;
}

.secondary__header-container {
  text-align: center;
}

.secondary__header {
  font-size: 2.4rem;
  font-style: bold;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.tertiary__header {
  font-size: 3.4rem;
  text-transform: capitalize;
}

/* HELPERS */
.hidden {
  display: none;
}

/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}
