* {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: #fff;
  font-family: Open Sans, sans-serif;
  overflow: hidden;
}

.container {
  width: 120rem;
}

.section-container {
  max-width: 120rem;
  padding: 20rem 14rem 6rem;
}

.secondary__section-container {
  max-width: 120rem;
  padding: 6rem 14rem;
}

.secondary__header-container {
  text-align: center;
}

.secondary__header {
  font-size: 2.4rem;
  font-style: bold;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 800;
}

.tertiary__header {
  text-transform: capitalize;
  font-size: 3.4rem;
}

.hidden {
  display: none;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  color: #333;
  width: 4.8rem;
  height: 4.8rem;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/*# sourceMappingURL=index.912b75a2.css.map */
